import styled from 'styled-components';

const ChatContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  right: 0;
  height: 100%;
  width: 300px;
  background: #1f1f1f;
  transition: width .5s;
  box-sizing: content-box;
  z-index: 51;

  &.hide {
    width: 0px;
  }
  `

const Chat =  styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  padding: 10px 10px 15px;
  box-sizing: border-box;
  `
const Header = styled.header`
  display: flex;
  width: 100%;
  height: 5%;
`

const CloseBottom = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
`

export default { 
  ChatContainer,
  Chat,
  Header,
  CloseBottom,
  };