import React from 'react';
import Styled from './styles'

const MeetingEnded = () => {

  return(
    <Styled.Parent>
      <Styled.Modal data-test="meetingEndedModal">
        <Styled.Content>
          <Styled.Title>
            已離開會議室
          </Styled.Title>
        </Styled.Content>
      </Styled.Modal>
    </Styled.Parent>
  )
}

export default MeetingEnded