import _ from "lodash";
import { isMobile, isSafari } from 'react-device-detect';
import BulletJs from "js-bullets";
import { escapeHTML } from "../api/service";

let bulletCount = 0
const bulletLimit = isMobile ? 40 : 100

const settings = {
  time: 5,
  color: ['#ffffff', '#4DFFFF', '#53FF53'],
  fontSize: '1.5rem',
  fontWeight: 900,
  height: 50
}

// const getRandom = (min, max) => {
//   return Math.floor(Math.random() * (max - min + 1)) + min;
// };

// export const bulletChat = _.throttle((message, target = document.body, mode = 'horizontal') => {
//   if(!target || bulletCount >=bulletLimit){
//     return
//   }
//   bulletCount++

//   const viewport_height = target.clientHeight;
//   const viewport_width = window.outerWidth;
//   const offsetY = getRandom(0, (viewport_height / 3));
//   let div = document.createElement('div')
//   div.innerHTML = message
//   const Translate = null
//   const horizontal =  `
//     position: absolute;
//     left: ${viewport_width}px;
//     top: ${offsetY}px;
//     transition: transform ${settings.time}s;
//     color: ${settings.color[Math.floor(Math.random() * settings.color.length)]};
//     font-size: ${settings.fontSize};
//     font-weight: ${settings.fontWeight};
//     transition-timing-function: linear;
//     -webkit-text-stroke: 1px #000000;
//     width: max-content;
//     pointer-events: none;
//     z-index: 50;
//   `
  
//   switch(mode){
//     case 'horizontal':
//       div.style.cssText = horizontal
//       Translate = `translateX(-${viewport_width + div.clientWidth}px)`
//       break
//     default:
//       div.style.cssText = horizontal
//       Translate = `translateX(-${viewport_width + div.clientWidth}px)`
//   }
//   target.append(div)

//   setTimeout(() => {
//     div.style.transform = Translate
//     setTimeout(() => {
//       div.remove()
//       bulletCount--
//     }, settings.time * 1000);
//   }, 1000);
// },150)

let screen = null
const speed = 200

export const bulletChat = _.throttle((message, target)=>{
  if(bulletCount >=bulletLimit && !document.querySelector(target)){
    return
  }
  
  const speed = 100;
  const height = 50;
  const textStroke = '1px #8E8E8E';
  const textShadow = isSafari ? 'unset' : '0 0 4px #8E8E8E';
  // const color = ['#FFFFFF', '#000000'];
  // const color = textColorPrimary;
  const color = 'white';
  const fontWeight = 900;

  bulletCount++
  !screen && (screen = new BulletJs(target, {
    speed,
    trackHeight: settings.height,
    onEnd: ()=>{
      bulletCount--
    },
    trackArr:[{speed},{speed}]
  }))

  screen.push(`
    <div style='position: relative; padding: 0px 3px;'>
      <p style='
        margin: auto;
        height: ${height}px;
        // color: ${color[Math.floor(Math.random() * color.length)]};
        color: ${color};
        font-size: 1.5rem;
        font-weight: ${fontWeight};
        line-height: ${height}px;
      '>
        ${escapeHTML(message)}
      </p>
      <p style='
        position: absolute;
        z-index: -1;
        top: 0;
        margin: auto;
        height: ${height}px;
        font-size: 1.5rem;
        font-weight: ${fontWeight};
        line-height: ${height}px;
        -webkit-text-stroke: ${textStroke};
        text-stroke: ${textStroke};
        text-shadow: ${textShadow};
      '>
        ${escapeHTML(message)}
      </p>
    </div>`)
}, 200)