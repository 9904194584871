import _ from "lodash";
import React, { useRef } from "react";
import styled from './styles';

function MessageForm(props){
  const refInput = useRef()
  const {
    submit
  } = props

  const inputKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleSubmit()
      e.preventDefault();
    }else if(e.keyCode === 13 && e.shiftKey && e.target.value == ''){
      e.preventDefault()
    }
  }
  
  const handleSubmit = _.throttle(() => {
    const textarea = refInput.current
    if(textarea.value == '' || !/[^\s]/.test(textarea.value)){
      return
    }
    submit(textarea.value.trim())
    textarea.value = ''
  }, 0)

  return(
    <styled.InputArea>
      <styled.Input
        ref={refInput}
        onKeyDown={(e) => inputKeyDown(e)}
        spellCheck="true"
        autoCorrect="off"
        autoComplete="off"
        tabIndex="-1"
        maxLength="48"
        async
      />
      <styled.Button onClick={handleSubmit} >
      <img style={{width: '1rem', height: '1rem', transform: 'rotate(180deg)'}} src="icons/icon_left_arrow.svg" />
      </styled.Button>
  </styled.InputArea>
  )
}

export default MessageForm