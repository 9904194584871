import styled from 'styled-components';

const MainContainer = styled.div`
  display: flex;
  position: relative;
  height: 100dvh;
  width: 100vw;
`

const MediaWapper = styled.div`
  position: relative;
  transition: width .5s;
`

export default {
  MainContainer,
  MediaWapper
}