import React, { useCallback, useContext, useEffect, useState } from "react";
import Emoji from "../emoji/component";
import styled from './styles';
import { getMeetingSessionToken } from "../../api/service"
import Swal from 'sweetalert2';
import context from "../layout/context";
import { isMobile } from "react-device-detect";

const ActionBar = () => {
  const refActionBar = React.createRef()
  const [isShowEmojiList, setIsShowEmojiList] = useState(false)
  const [ActionBarPosition, setActionBarPosition] = useState({position: 0, pointerEvent: "all"})
  const userData = useContext(context).state.UserData
  const dispatch = useContext(context).dispatch
  let timecheck = null
  
  const joinAsk = () =>{
    Swal.fire({
      title: '等待許可中...',
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: '取消',
      background: '#1d1d1d',
      color: '#fff',
      allowOutsideClick: false
    }).then(async (result) => {
      if (!result.isConfirmed) {
        console.log( await getMeetingSessionToken(userData.username))
      }
    })
  }
  
  const showEmojiList = useCallback(() => {
    window.addEventListener('click',hideEmojiList)
    setIsShowEmojiList(true)
  },[true])

  const hideEmojiList = useCallback(() => {
    window.removeEventListener('click',hideEmojiList)
    setIsShowEmojiList(false)
  },[true])

  function leaveSession() {
    document.fullscreenElement && document.exitFullscreen()
    Swal.fire({
      title: '會議正在進行中',
      text: '是否要離開會議？',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '確定',
      cancelButtonText: '取消',
      background: '#1d1d1d',
      color: '#fff',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({
          type: 'roomData',
          value: {
            logout: true
          }
        })
      }
    })
  }

  useEffect(()=>{
    const originHeight = `${refActionBar.current.clientHeight - 5}px`
    const ActionBarContainer = refActionBar.current.parentNode

    const whenmouseenter = () => {
      setActionBarPosition({position: 0, pointerEvent: "all"})
      clearTimeout(timecheck)
    }

    const whenmouseleave = () => {
      timecheck = setTimeout(() => {
        setActionBarPosition({position: originHeight, pointerEvent: "none"})
        hideEmojiList()
      }, 1000);
    }

    const whentouch = () => {
      setActionBarPosition({position: 0, pointerEvent: "all"})
      clearTimeout(timecheck)
    }

    const whentouchend = () => {
      timecheck = setTimeout(() => {
        setActionBarPosition({position: originHeight, pointerEvent: "none"})
      }, 5000);
    }

    !isMobile && ActionBarContainer.addEventListener('mouseenter', whenmouseenter)
    !isMobile && ActionBarContainer.addEventListener('mouseover', whenmouseenter)
    !isMobile && ActionBarContainer.addEventListener('mouseleave', whenmouseleave)
    isMobile && window.addEventListener('touchstart', whentouch)
    isMobile && window.addEventListener('touchmove', whentouch)
    isMobile && window.addEventListener('touchend', whentouchend)

    let firstHide = setTimeout(() => {
      setActionBarPosition({position: originHeight, pointerEvent: "none"})
    }, 1000);
    
    return () => {
      clearTimeout(timecheck)
      clearTimeout(firstHide)
    }
  },[])
  const urlParams = new URLSearchParams(window.location.search);
  const history = urlParams.get('history')
  return (
    <styled.ActionBarContainer
      data-test = "actionBar"
      style={{
        width: isMobile ? "100%" : '50%',
        left: '50%',
        transform: 'translateX(-50%)',
        transition: "width .5s, transform .5s"
      }}
    >
      <styled.ActionBar
        ref = {refActionBar}
        style = {{
          transform: `translate(-50%, ${ActionBarPosition.position})`,
          pointerEvents: ActionBarPosition.pointerEvent
        }}
      >
        <styled.HideBotton 
        className="microphone_off"
        style={{
          cursor: "pointer"
        }}
        // onClick={joinAsk}
        >
          <img src={'icons/icon_microphone_off.svg'} />
        </styled.HideBotton >
        <styled.HideBotton className="camera_off">
          <img src={'icons/icon_camera_off.svg'} />
        </styled.HideBotton >
        <styled.EmojiBotton className="emoji"
        onClick={(e) => {
          if(history == '1'){
            return
          }
          e.stopPropagation()
          isShowEmojiList ? hideEmojiList() : showEmojiList()
          }}
          >
          <img src={'icons/icon_emoji.svg'} />
          {isShowEmojiList && <Emoji />}
        </styled.EmojiBotton>
        <styled.HideBotton className="share">
          <img src={'icons/icon_share.svg'} />
        </styled.HideBotton >
        <styled.LeaveMeetingButton onClick={() => leaveSession() }>
          <img src={'icons/icon_hangup.svg'} />
        </styled.LeaveMeetingButton>
      </styled.ActionBar>
    </styled.ActionBarContainer>
)
}

export default ActionBar;
