import styled from 'styled-components';
import TextareaAutosize from 'react-autosize-textarea';

const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Input = styled(TextareaAutosize)`
  width: 80%;
  line-height: 1;
  color: white;
  background: transparent;
  resize: none;
  outline: none;
  padding: 5px;
  box-sizing: border-box;
  min-height: 2.5rem;
  max-height: 10rem;
  line-height: 1;
  font-size: 1rem;
  border: 1px solid #A7B3BD;
  border-radius: .25rem;
  box-shadow: 0 0 0 1px #A7B3BD;
  padding: calc(0.3rem * 2.5) calc(0.75rem * 1.25);

  &:focus {
    border-radius: 2px;
    box-shadow: 0 0 0 2px #54a1f3, inset 0 0 0 1px #0F70D7;
  }
`

const Button = styled.button`
  background: #0F70D7;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
`

export default {
  InputArea,
  Input,
  Button,
  };