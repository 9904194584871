import styled from 'styled-components';

const BottonDiameter = '22px'
const BottonPadding = '5px'

const VolumeSlideWapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 50%;
  height: 120px;
  background-color: white;
  cursor: pointer;
`
const VolumeSlidebar = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  height: calc(100% - ${BottonDiameter} - ${BottonPadding});
  background-color: gray;
  border-radius: 10px;
  pointer-events: none;
`

const VolumeBotton = styled.div`
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${BottonDiameter};
  height: ${BottonDiameter};
  background-color: black;
  border-radius: 50%;
  pointer-events: none;
`

export default { 
  VolumeSlideWapper,
  VolumeSlidebar,
  VolumeBotton
  };