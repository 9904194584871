import { io } from "socket.io-client";

const urlParams = new URLSearchParams(window.location.search);
const liveSessionToken = urlParams.get('liveSessionToken')
// const liveSessionToken = '-MYo2Au0H0Qf7GBPSxumm2G0I85fk-yMoIohKi5AjmpTcup-AV9tlzt-TJB9bKIVzI_KbQLdUnQlSpDn_aFTVcfcXdAuWD5mthrV8C0Z9d8'

export const streamWs = io(process.env.REACT_APP_STREAM_WSS_ENDPOINT, {
  transports: ['websocket'],
  auth: {
    token: liveSessionToken
  },
  reconnectionDelayMax: 2000
})

export const chatWs = io(process.env.REACT_APP_CHAT_WSS_ENDPOINT, {
  'transports': ['websocket'],
  // auth: {
  //   token: liveSessionToken
  // },
  // reconnectionDelayMax: 2000
})

export const emojiWs = io(process.env.REACT_APP_EMOJI_WSS_ENDPOINT, {
  'transports': ['websocket'],
  // auth: {
  //   token: liveSessionToken
  // },
  // reconnectionDelayMax: 2000
})

export const getMeetingSessionToken = (name) => {
  // return fetch('https://webinar-dev2.mpluscloud.com/m/join-meeting',{
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     "Cache-Control": "no-cache"
  //   },
  //   body: JSON.stringify({
  //     "method": "1",
  //     "nickname": name,
  //     "greenlightId": "leo-zez-zct-br9"
  //   })
  // }).then(response => {
  //   return response.json();
  // }).then((data) => {
  //   return sessionToken = data.joinUrl?.split("=")[1]
  // })
  
  const urlParams = new URLSearchParams(window.location.search);
  const liveSessionToken = urlParams.get('liveSessionToken');
  const liveRoomId = urlParams.get('liveRoomId');
  return fetch('https://webinar-dev2.mpluscloud.com/m/join-meeting-from-live',{
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache"
    },
    body: JSON.stringify({
      "liveSessionToken": liveSessionToken,
      "approveToken": 'test',
      "liveRoomId": liveRoomId
    })
  }).then(response => {
    console.log(response)
    return response.json();
  }).then((data)=>{
    console.log(data)
    let url = new URL(data.joinUrl)
    const urlParams = new URLSearchParams(url.search);
    const sessionToken = urlParams.get('sessionToken');
    return sessionToken
  })
}

export function escapeHTML (unsafe_str) {
  return unsafe_str
  .replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/\"/g, '&quot;')
  .replace(/\'/g, '&#39;')
  .replace(/\//g, '&#x2F;')
}

export const withTimeout = (onSuccess, onTimeout, timeout) => {
  let called = false;

  const timer = setTimeout(() => {
      if (called) return;
      called = true;
      onTimeout();
  }, timeout);

  return (...args) => {
      if (called) return;
      called = true;
      clearTimeout(timer);
      onSuccess.apply(this, args);
  }
}