import styled from 'styled-components';

const EmojiListWapper = styled.div`
  position: absolute;
  bottom: 160%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 25px;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  background: black;
  border: 1px solid #707070;
`

const EmojiListBotton = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 5px 2px;
  cursor: pointer;

  &:hover{
    background: #0F70D7;
  }
`

const EmojiImage = styled.img`
  width: 28px;
  height: 28px;
`

export default {
  EmojiListWapper,
  EmojiListBotton,
  EmojiImage
}