import QwitcherGrypenRegular from './QwitcherGrypenRegular'

class watermake {
  constructor(){
    this.defaultSetting = {
      id: 'wm_div_id',
      text: 'leochung',
      rows: 0,
      cols: 0,
      x_space: 50,
      y_space: 50,
      color: 'white',
      fontSize: '18px',
      alpha: 0.15,
      width: 50,
      height: 50,
      angle: -45,
    }
    this.windowSize = {
      width: 0,
      height: 0
    }
    this.init.bind(this)
    this.reset.bind(this)
    this.wrapper = null
    this.bgSVG = null
    this.className = 'watermarks'
    this.resetInterval = null
  }

  init(setting = {}){
    Object.keys(setting).forEach((data)=>{
      this.defaultSetting[data] = setting[data]
    })

    const body = document.body
    this.windowSize.width = body.clientWidth
    this.windowSize.height = body.clientHeight

    let text = this.defaultSetting.text;
    text = btoa(encodeURIComponent(text));

    const fontData = QwitcherGrypenRegular;
    this.bgSVG = "<svg xmlns='http://www.w3.org/2000/svg' width='260' height='124' transform='rotate(325)' opacity='0.4'> <defs><style>@font-face {font-family: 'custom_font';src: url("+fontData+");}</style></defs>  <text font-family='custom_font' style='fill:rgb(200, 200, 200);'><tspan x='8' y='56' style='font-size: 15px;'>" + text + "</tspan></text>Sorry, your browser does not support inline SVG.</svg>";

    const jack = document.createElement('style');
    jack.innerHTML = `
    .watermarks:before{
      content: "";
      position: absolute!important;
      width: 100%!important;
      height: 100%!important;
      max-width: 100%!important;
      max-height: 100%!important;
      opacity: 1!important;
      pointer-events: none!important;
      background-image: url("data:image/svg+xml,${this.bgSVG}")!important;
      z-index: 9999!important;
      transform: unset!important;
      animation: none!important;
    }`;

    body.querySelector('style') && body.removeChild(body.querySelector('style'))
    body.insertBefore(jack, body.firstChild)
    
    // const wrapper = document.createElement('div')
    // wrapper.style.cssText = `
    //   width: ${this.windowSize.width}px;
    //   height: ${this.windowSize.height}px;
    //   overflow: hidden;
    //   pointer-events: none;
    //   position: absolute;
    //   top: 0;
    //   opacity: ${this.defaultSetting.alpha};
    // `
    // this.wrapper && this.wrapper.remove()
    // body.append(wrapper)
    // this.wrapper = wrapper
    // const x = Math.ceil(this.windowSize.width / (this.defaultSetting.width + this.defaultSetting.x_space))
    // const y = Math.ceil(this.windowSize.height / (this.defaultSetting.height + this.defaultSetting.y_space))
    // const column = document.createElement('div')
    // column.style.cssText = `
    //   display: flex;
    //   flex-direction: column;
    //   align-items: start;
    // `

    // for(let i = 1; i <= y; i++){
    //   let row = document.createElement('div')
    //   row.style.cssText = `
    //     display: flex;
    //     flex-direction: row;
    //     align-items: start;
    //   `
    //   for(let j = 1; j <= x; j++){
    //     let item = document.createElement('div')
    //     item.innerText = this.defaultSetting.text
    //     item.style.cssText = `
    //       padding: ${this.defaultSetting.y_space / 2}px ${this.defaultSetting.x_space / 2}px;
    //       transform: rotate(${this.defaultSetting.angle}deg);
    //       font-size: ${this.defaultSetting.fontSize};
    //       color: ${this.defaultSetting.color};
    //       height: ${this.defaultSetting.height}px;
    //       width: ${this.defaultSetting.width}px;
    //     `

    //     row.append(item)
    //   }
      
    //   column.append(row)
    // }
    // wrapper.append(column)

    // window.onresize = () =>{
    //   if(document.body.clientHeight > this.windowSize.height || document.body.clientWidth > this.windowSize.width) this.init()
    // }
    this.reset()
  }

  add(targetElement){
    clearInterval(this.resetInterval)
    this.windowSize.width = targetElement.clientWidth
    this.windowSize.height = targetElement.clientHeight

    let text = this.defaultSetting.text;
    text = btoa(encodeURIComponent(text));

    const fontData = QwitcherGrypenRegular;
    const bgSVG = "<svg xmlns='http://www.w3.org/2000/svg' width='260' height='124' transform='rotate(325)' opacity='0.4'> <defs><style>@font-face {font-family: 'custom_font';src: url("+fontData+");}</style></defs>  <text font-family='custom_font' style='fill:rgb(200, 200, 200);'><tspan x='8' y='56' style='font-size: 15px;'>" + text + "</tspan></text>Sorry, your browser does not support inline SVG.</svg>";

    const jack = document.createElement('style');
    jack.innerHTML = '.watermarks:before{background-image: url("data:image/svg+xml,' + bgSVG + '");}';
    targetElement.querySelector('style') && targetElement.removeChild(targetElement.querySelector('style'))
    targetElement.insertBefore(jack, targetElement.firstChild)
    this.reset(targetElement)
  }

  makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  
  reset(target = document.body){
    this.resetInterval = setInterval(() => {
      const body = target
      body.classList.remove(this.className)
      body.querySelector('style') && body.removeChild(body.querySelector('style'))
      const jack = document.createElement('style');
      this.className = this.makeid(8)
      body.classList.add(this.className)
  
      jack.innerHTML = `
      .${this.className}:before{
        content: "";
        position: absolute!important;
        width: 100%!important;
        height: 100%!important;
        max-width: 100%!important;
        max-height: 100%!important;
        opacity: 1!important;
        pointer-events: none!important;
        background-image: url("data:image/svg+xml,${this.bgSVG}")!important;
        z-index: 9999!important;
        transform: unset!important;
        animation: none!important;
      }`;
      
      body.insertBefore(jack, body.firstChild)
    }, 1000);
  }
}

export default new watermake