import styled from 'styled-components';

const NavBarContainer = styled.div`
  position: relative;
  top: 0%;  
  width: 100%;
  height: 80px;
  padding: 5px 10px;
  box-sizing: border-box;
`

const NavBar =  styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`
const Left = styled.div`
  display: flex;
  align-items: center;
  color: white;
  pointer-events: none;
  flex-direction: column;
  justify-content: space-around;

  & > div.title {
    font-size: 1.25rem;
  }
  
  & > div.time {
    font-size: 1.25rem;
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  color: white;
`

const PeopleNumber = styled.div`
  cursor: default;
`

const ChatWapper = styled.div`
  position: relative;
  width: 50px;
  Height: 50px;
  margin: 0 .75rem;
  cursor: pointer;
  -Webkit-user-drag: none;

  ${({ hasNotification }) => hasNotification && `
    position: relative;

    &:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      bottom: 0px;
      right: 1px;
      background-color: red;
      border: 1px solid #06172A;
      pointer-events: none;
    }
  `}
`

export default { 
  NavBarContainer,
  NavBar,
  Left,
  Right,
  PeopleNumber,
  ChatWapper
  };