import React from "react";
import styled from './styles';
import { escapeHTML } from "../../../api/service";

function ChatTextComponent(props){
  const {
    name,
    message,
    time
  } = props.data
  return(
    <styled.Wrapper style={{
      height: props.height
    }} >
      <styled.Content>
        <styled.Meta>
          <styled.Name>
          {escapeHTML(name)}
          </styled.Name>
          <styled.Time>
            {time}
          </styled.Time>
        </styled.Meta>
        <styled.Message dangerouslySetInnerHTML={{ __html: escapeHTML(message) }} />
      </styled.Content>
    </styled.Wrapper>
  )
}

export default ChatTextComponent