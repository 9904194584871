import React, { useState, useContext, useEffect, useRef } from "react";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import styled from './styles';
import context from '../layout/context';
import { isMobile } from "react-device-detect";
import TimeWindowValue from "../chat/time-window-value/conponent";
import watermark from '../../api/watermark';
import ResetButtonContainer from './reset/container';
import { streamWs } from "../../api/service";

export const VideoJS = (props) => {
  const VideoJSContainerref = React.createRef()
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const [firstrender, setfirstrender] = useState(false)
  const [showresetBotton, setShowresetBotton] = useState(false)
  const [videoRatio, setVideoRatio] = useState(1)
  const [contentBox, setContentBox] = useState({
    display: 'none',
    left: 0,
    top: 0
  })
  const [setting, setSetting] = useState(false)
  const [videoData, setVideoData] = useState({
    buffer: null,
    loss: null,
    droppedVideoFrames: 0,
    totalVideoFrames: 0,
    currentTime: 0
  })
  const refTimeout = useRef()
  const chat = useContext(context).state.Chat
  const urlParams = new URLSearchParams(window.location.search);
  const history = urlParams.get('history')
  const {
    onReady,
    livestreamUrl,
    setStreamState,
    mediaSource,
    setShowVideo,
    userData
  } = props;
  const [liveStreamComtainerSize, setLiveStreamComtainerSize] = useState({width:0,height:0})
  const sendQulity = React.useRef(()=>{})
  const [pauseCount, setPauseCount] = useState(0)

  mediaSource !== 'livestream' && registerIVSTech(videojs)

  useEffect(()=>{
    window.addEventListener('fullscreenchange', ()=>{
      if(document.fullscreenElement !== null){
        document.fullscreenElement.classList.add('watermarks')
        watermark.add(document.fullscreenElement)
      }else{
        watermark.add()
      }
    })

    setInterval(() => {
      sendQulity.current()
    }, 10000);
  },[])

  useEffect(()=>{
    const utcDate = new Date();
    sendQulity.current = ()=>{
      videoData.buffer !== null && fetch('https://receive-web-play-log-457453849177.asia-east1.run.app',{
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          mplusId: userData.token_info.mplusId,
          username: userData.username,
          socketId: streamWs.id,
          ...videoData,
          buffer: videoData.buffer.end(0) - videoData.currentTime,
          pauseCount: pauseCount,
          time: utcDate.toString()
        }),
        method: 'POST'
      })
    }
  },[videoData])

  React.useEffect(() => {
    const options = {
        autoplay: 'play',
        controls: true,
        responsive: true,
        fluid: true,
        audioOnlyMode: false,
        // techOrder: ["AmazonIVS"],
        liveui: false,
        sources: [{
            src: livestreamUrl,
            type: 'application/x-mpegURL'
        }],
        controlBar: {
          playToggle: false,
          pictureInPictureToggle: false,
        }
    };
    mediaSource !== 'livestream' && (options.techOrder = ["AmazonIVS"])
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");
      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        mediaSource !== 'livestream' && player.src(options.sources)
        onReady && onReady(player);
      });
    // You could update an existing player in the `else` block here
    // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      mediaSource !== 'livestream' && player.src(options.sources);
    }
    playerRef.current.off('play')
    playerRef.current.on('play',()=>{
      setfirstrender(true)
      getbuffer()
    })
    playerRef.current.off('waiting')
    playerRef.current.on('waiting',()=>{
      setStreamState({
        state: true,
        text: '分享畫面加載中'
      })
      setPauseCount(pauseCount + 1)
    })
    playerRef.current.off('playing')
    playerRef.current.on('playing',()=>{
      history !== '1' && playerRef.current.children_[0].duration - playerRef.current.children_[0].currentTime > 10 &&  (playerRef.current.children_[0].currentTime = playerRef.current.children_[0].duration)
      resize()
      setStreamState({
        state: false,
        text: ''
      })
    })
    playerRef.current.off('ended')
    playerRef.current.on('ended',()=>{
      setStreamState({
        state: true, 
        text: '偵測到畫面停止，將於30秒後重新整理'
      })
      setTimeout(() => {
        setShowVideo(false)
      }, 30000);
    })
    playerRef.current.off('error')
    playerRef.current.on('error',()=>{
      setStreamState({
        state: true,
        text: '分享畫面發生錯誤，請重整頁面'
      })
    })

    const reset = () => {
      window.removeEventListener('click', reset)
      setContentBox({
        display: 'none',
        left: 0,
        top: 0,
      })
    }

    videoRef.current.addEventListener('contextmenu', (e) => {
      e.preventDefault()
      setContentBox({
        display: 'initial',
        left: e.offsetX,
        top: e.offsetY,
      })
      window.addEventListener('click', reset)
    })

    playerRef.current.liveTracker.on('liveedgechange', () => {
      videojs.log('follow latest frame');
      playerRef.current.liveTracker.seekToLiveEdge();
    })

    history !== '1' && setInterval(() => {
      const video = document.querySelector('video')
      video && video.duration - video.currentTime > 10 && (video.currentTime = video.duration)
    }, 20000);
  }, [livestreamUrl, videoRef]);

  useEffect(()=>{
    playerRef.current.off('waiting')
    playerRef.current.on('waiting',()=>{
      setStreamState({
        state: true,
        text: '分享畫面加載中'
      })
      setPauseCount(pauseCount + 1)
    })
  },[pauseCount])

  let getbuffer = () => {
    const video = document.querySelector('video')
    const quality = video.getVideoPlaybackQuality();
    const dropPercent = isNaN(((quality.droppedVideoFrames - videoData.droppedVideoFrames) / (quality.totalVideoFrames - videoData.totalVideoFrames)).toFixed(2) * 100) ? '0%' : ((quality.droppedVideoFrames - videoData.droppedVideoFrames) / (quality.totalVideoFrames - videoData.totalVideoFrames)).toFixed(2) * 100 + '%'
    setVideoData({
      buffer: playerRef.current.buffered(),
      loss: dropPercent,
      droppedVideoFrames: quality.droppedVideoFrames,
      totalVideoFrames: quality.totalVideoFrames,
      currentTime: playerRef.current.currentTime()
    })
    refTimeout.current = setTimeout(() => {
      getbuffer()
    }, 500);
  }

  useEffect(()=>{
    if(!videoData.loss) return
    clearTimeout(refTimeout.current)
    getbuffer = () => {
      const video = document.querySelector('video')
      const quality = video.getVideoPlaybackQuality();
      const dropPercent = isNaN(((quality.droppedVideoFrames - videoData.droppedVideoFrames) / (quality.totalVideoFrames - videoData.totalVideoFrames)).toFixed(2) * 100) ? '0%' : ((quality.droppedVideoFrames - videoData.droppedVideoFrames) / (quality.totalVideoFrames - videoData.totalVideoFrames)).toFixed(2) * 100 + '%'
      setVideoData({
        buffer: playerRef.current.buffered(),
        loss: dropPercent,
        droppedVideoFrames: quality.droppedVideoFrames,
        totalVideoFrames: quality.totalVideoFrames,
        currentTime: playerRef.current.currentTime()
      })
      refTimeout.current = setTimeout(() => {
        getbuffer()
      }, 500);
    }
    getbuffer()
  },[videoData.loss])

  useEffect(()=>{
    resize()
    window.removeEventListener('resize',resize)
    window.addEventListener('resize',resize)

    if(firstrender){
      let VideoJSContainer = VideoJSContainerref.current
      VideoJSContainer.style.transition = 'width .5s, height .5s'
      setTimeout(() => {
        VideoJSContainer.style.transition = ''
      }, 500);
    }
  },[chat.width])

  const setResetButton = ( scale = 1, translateX = 0, translateY = 0) => {
    setShowresetBotton(!(scale == 1 && translateX == 0 && translateY == 0))
  }

  useEffect(()=>{
    if(firstrender && !isMobile){
      const video = document.querySelector('video')
      const controlPanel = document.createElement('div')
      controlPanel.className = 'controlPanel'
      controlPanel.style.cssText = 'position: absolute;top: 0;left: 0;width: 100%;height: 100%;cursor: grab;'
      video.parentElement.prepend(controlPanel)
      
      let videoMouseDown = false

      controlPanel.addEventListener('wheel',(e)=>{
        if(document.pictureInPictureElement || document.fullscreenElement) return
        let scale = parseFloat(video.style.transform == '' ? 1 : video.style.transform.split('scale(')[1].split(')')[0],10)
        let translateX = parseFloat(video.style.transform == '' || !video.style.transform.split('translateX(')[1] ? 0 : video.style.transform.split('translateX(')[1].split('px)')[0],10)
        let translateY = parseFloat(video.style.transform == '' || !video.style.transform.split('translateY(')[1] ? 0 : video.style.transform.split('translateY(')[1].split('px)')[0],10)
        video.style.transform = `scale(${scale}) translateX(${translateX + e.movementX/scale}px) translateY(${translateY + e.movementY/scale}px)`
        if(e.deltaY < 0){
          video.style.transform = `scale(${Math.min(4, scale + 0.1)}) translateX(${translateX}px) translateY(${translateY + e.movementY/scale}px)`
          controlPanel.style.transform = `scale(${Math.min(4, scale + 0.1)}) translateX(${translateX}px) translateY(${translateY + e.movementY/scale}px)`
          setResetButton(Math.min(4, scale + 0.1), translateX, translateY + e.movementY/scale)
          setVideoRatio(Math.min(4, scale + 0.1))
        }else{
          video.style.transform = `scale(${Math.max(1, scale - 0.1)}) translateX(${translateX}px) translateY(${translateY + e.movementY/scale}px)`
          controlPanel.style.transform = `scale(${Math.max(1, scale - 0.1)}) translateX(${translateX}px) translateY(${translateY + e.movementY/scale}px)`
          setResetButton(Math.max(1, scale - 0.1), translateX, translateY + e.movementY/scale)
          setVideoRatio(Math.max(1, scale - 0.1))
        }
      })

      controlPanel.addEventListener('mousedown', (e) => {
        e.preventDefault()
        videoMouseDown = true
      })

      controlPanel.addEventListener('mousemove',(e)=>{
        if(videoMouseDown && !document.fullscreenElement && !document.pictureInPictureElement){
          let scale = parseFloat(video.style.transform == '' ? 1 : video.style.transform.split('scale(')[1].split(')')[0],10)
          let translateX = parseFloat(video.style.transform == '' || !video.style.transform.split('translateX(')[1] ? 0 : video.style.transform.split('translateX(')[1].split('px)')[0],10)
          let translateY = parseFloat(video.style.transform == '' || !video.style.transform.split('translateY(')[1] ? 0 : video.style.transform.split('translateY(')[1].split('px)')[0],10)

          let newTranslateX = (video.parentElement.clientWidth/2 <= video.clientWidth ? video.clientWidth/2 : video.offsetLeft) >= -(translateX + e.movementX/scale) && (video.parentElement.clientWidth/2 <= video.clientWidth ? video.clientWidth/2 : video.offsetLeft) >= translateX + e.movementX/scale ? translateX + e.movementX/scale : translateX
          let newTranslateY = (video.parentElement.clientHeight == video.clientHeight ? video.clientHeight/2 : video.offsetTop) <= -(translateY + e.movementY/scale) || (video.parentElement.clientHeight == video.clientHeight ? video.parentElement.clientHeight - video.offsetTop - video.clientHeight/2 : video.parentElement.clientHeight - video.offsetTop - video.clientHeight) <= translateY + e.movementY/scale ? translateY : translateY + e.movementY/scale
          
          controlPanel.style.transform = `
          scale(${scale}) 
          translateX(${newTranslateX}px) 
          translateY(${newTranslateY}px)`
          
          video.style.transform = `
          scale(${scale}) 
          translateX(${newTranslateX}px) 
          translateY(${newTranslateY}px)`

          setResetButton(scale, newTranslateX, newTranslateY)
        }
      })
      window.addEventListener('mouseup',(e)=>{
        e.preventDefault()
        videoMouseDown = false
      })
    }
  },[firstrender])

  function onReseted(){
    document.querySelector('video').style.transform = ''
    setVideoRatio(1)
    setResetButton()
  }

  function renderResetButton() {
    return (
      document.pictureInPictureEnabled && showresetBotton ?
      <ResetButtonContainer
        handleReset={onReseted}
      /> :
      <></>
    );
  }

  const resize = () => {
    const video = document.querySelector('video')
    let outpadding = document.querySelector("[data-test='navbar']").clientHeight + document.querySelector("[data-test='actionBar']").clientHeight

    if((window.innerHeight - outpadding)/(window.innerWidth - (isMobile ? 0 : chat.width)) > video.videoHeight / video.videoWidth){setLiveStreamComtainerSize({
        width: isMobile ? window.innerWidth : window.innerWidth - chat.width < 550 ? 550 : window.innerWidth - chat.width,
        height: isMobile ? window.innerWidth * (video.videoHeight / video.videoWidth) : window.innerWidth - chat.width < 550 ? 550 * (video.videoHeight / video.videoWidth) : (window.innerWidth - chat.width) * (video.videoHeight / video.videoWidth)
      })
    }else{setLiveStreamComtainerSize({
        width: (window.innerHeight - outpadding) * (video.videoWidth / video.videoHeight),
        height: window.innerHeight - outpadding
      })
    }
  }

  const renderSetting = () => {
    return(
      <div style={{
        position: 'absolute',
        top: '10px',
        left: '10px',
        color: 'white'
      }}
      onClick={()=>{
        setSetting(false)
      }}
      >
        mplusId: {userData.token_info.mplusId}
        <br/>
        Buffer: {videoData.buffer.end(0)}
        <br/>
        CurrentTime: {videoData.currentTime}
        <br/>
        Loss: {videoData.loss}
        <br/>
        droppedVideoFrames: {videoData.droppedVideoFrames}
      </div>
    )
  }

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    return () => {
        try {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        } catch(e) {
            console.warn(e);
        }
    };
  }, [playerRef]);

  return (
    <styled.VideoJSContainer
    ref={VideoJSContainerref}
    id='videoPlayer'
    style={{
      height: window.innerHeight - document.querySelector("[data-test='navbar']")?.clientHeight - document.querySelector("[data-test='actionBar']")?.clientHeight,
      overflow: 'hidden'
    }}>
      <div data-vjs-player style={{
        width: liveStreamComtainerSize.width,
        height: liveStreamComtainerSize.height,
        margin: 'auto'
      }}>
        <styled.VideoRatio className='animation' key={videoRatio} >+ {(videoRatio*100).toFixed(0)} %</styled.VideoRatio>
        {renderResetButton()}
        <div ref={videoRef} />
      </div>
      <styled.ChatContainer>
       <TimeWindowValue chat={chat} />
      </styled.ChatContainer>
    <styled.BulletBox id='bulletBox' />
    <div style={{
        position: 'absolute',
        display: contentBox.display,
        left: contentBox.left,
        top: contentBox.top,
      }}>
        <styled.Option style={{
          width: '100px',
          color: 'white',
        }}
        onClick={()=>{
          setSetting(true)
        }}
        >
          顯示網路品質
        </styled.Option>
      </div>
      {setting && renderSetting()}
    </styled.VideoJSContainer>
  );
}

export default VideoJS;