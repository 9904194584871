import React from "react";
import styled from "./styles";
import emojiMap from "../../api/emojis";
import { emojiWs } from "../../api/service";

const emojiList = ["astonished", "heart", "happy", "applause", "thumbsUp"]

const Emoji = (props) => {
  const emojiButtonClick = (type) => {
    emojiWs.emit("emojis",{
      action: 'user-clicked',
      name: type
    })
  }

  const emojiItem = emojiList.map((item, index)=>{
    return(
      <styled.EmojiListBotton key={index} onClick={()=>emojiButtonClick(item)}>
        <styled.EmojiImage src={"emojis/" + emojiMap[item]}/>
      </styled.EmojiListBotton>
    )
  })

  return(
    <styled.EmojiListWapper data-test="EmojiList">
      {emojiItem}
    </styled.EmojiListWapper>
  )
}

export default Emoji