import styled from 'styled-components';

const GameFrameContainer = styled.div`
  position: relative;  
  width: 100%;
  height: calc(100% - 140px);
  `

const GameFrame = styled.iframe`
  position: absolute;  
  width: 100%;
  height: calc(100% - 140px);
  left: 0;
  bottom: 0;
  `

const ToolBar = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flax;
`

const ToolBarButton = styled.div`
  height: 30px;
  width: 30px;
  margin: 0.5rem;
  cursor: pointer;
`

export default { 
  GameFrameContainer,
  GameFrame,
  ToolBar,
  ToolBarButton
};