import React, { useContext } from "react";
import context from "../../layout/context";
import Styled from './style'

const LoadingPage = (props) => {
  const chat = useContext(context).state.Chat
  
  return(
    <Styled.Wrapper
    style={{
      width: '100%'
    }}>
      <h1>{props.text}</h1>
    </Styled.Wrapper>
  )
}

export default LoadingPage