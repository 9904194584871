import React, { useContext, useState, useEffect } from "react";
import styled from './styles';
import context from '../layout/context'
import M3U8FileParser from 'm3u8-file-parser'
import { chatWs } from "../../api/service";

const Navbar = (props) => {
  const chat = useContext(context).state.Chat
  const dispatch = useContext(context).dispatch
  const [time, setTime] = useState({showTime: "", passTime: 0})
  const [peopleNumber, setPeopleNumber] = useState(0)
  const [hasSetTime, setHasSetTime] = useState(true)
  const urlParams = new URLSearchParams(window.location.search);
  const history = urlParams.get('history')
  const {
    showNotification,
    livestreamMediaFile,
    livestreamTitle
  } = props

  useEffect(()=>{
    hasSetTime && livestreamMediaFile && fetch(livestreamMediaFile)
    .then(responce=>responce.text())
    .then(r=>{
      const reader = new M3U8FileParser();
      reader.read(r);
      setInterval(() => {
        if (reader.getResult() && reader.getResult()['sessionData']) {
          const streamTime = reader.getResult()['sessionData']['STREAM-TIME']['undefined'].value;
          generateTime(streamTime, time);
        }
      }, 1000);
      setHasSetTime(false)
    })
  },[livestreamMediaFile])

  chatWs.on('statistics', function(responce) {
    const data = JSON.parse(responce);
    setPeopleNumber(data.payload.number)
  });

  const openChat = () => {
    dispatch({
      type: "chat",
      value: {
        width: chat.width == 0 ? 300 : 0
      }
    })
  }

  const generateTime = (streamTime, time) => {
    if (isNaN(streamTime)) {
      return;
    }
    const calcTime = parseFloat(streamTime) + time.passTime;
    const seconds = calcTime % 60  % 60 < 10 ? "0" +  Math.floor(calcTime % 60) : Math.floor(calcTime % 60);
    const minutes = Math.floor(calcTime / 60) % 60 < 10 ? "0" + Math.floor(calcTime / 60) % 60 : Math.floor(calcTime / 60) % 60
    const hours = Math.floor(calcTime / 3600);
    setTime({
      showTime: hours == 0 ? minutes == 0 ? "00:00:" + seconds : "00:" + minutes + ":" + seconds : hours + ":" + minutes + ":" + seconds,
      passTime: time.passTime++
    })
  };


  return (
    <styled.NavBarContainer data-test='navbar' >
      {history !== '1' &&<styled.NavBar>
        <styled.Left>
          <div className="title">{livestreamTitle}</div>
          <div className="time" style={{
            width: '100%',
            textAlign: 'left',
          }}>{time.showTime}</div>
        </styled.Left>
        <styled.Right>
          <styled.PeopleNumber>
            目前人數：{peopleNumber}
          </styled.PeopleNumber>
           { props.chatEnabled === 'true' &&
           <styled.ChatWapper>
               <img style={{
                 width: "50px",
                 Height: "50px",
                 margin: "0 .75rem",
                 cursor: "pointer",
                 WebkitUserDrag: "none",
               }}
                  src="icons/icon_chat.svg"
                  onClick={() => openChat()}
               />
            </styled.ChatWapper>
           }
        </styled.Right>
      </styled.NavBar>}
    </styled.NavBarContainer>
  )
}

export default Navbar;
