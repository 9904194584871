import React from 'react';
import Styled from './styles';

const ResetButtonComponent = (props) => {
  const {
    handleReset,
  } = props;

  return (
    <Styled.ResetButtonWrapper>
      <Styled.ResetButton
        src="icons/icon_100.svg"
        onClick={handleReset}
      />
    </Styled.ResetButtonWrapper>
  );
};

export default ResetButtonComponent;
