import styled from 'styled-components';

const Parent = styled.div`
  height: 100dvh;
  width: 100dvw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #06172A;
`;

const Modal = styled.div`
  display: flex;
  padding: 1.25rem;
  background-color: #FFF;
  flex-direction: column;
  border-radius: .2rem;
  max-width: 95vw;
  width: 600px;
`;

const Content = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
`;

export default {
  Parent,
  Modal,
  Content,
  Title,
}