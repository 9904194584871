import React, { useReducer } from 'react';

const initialState = {
  UserData: {
    name: ''
  },
  Chat: {
    width: 0
  },
  RoomData: {
    logout: false
  }
}

const context = React.createContext(null);

const reducer = (state, action) => {
  switch(action.type){
    case "chat":{
      const { 
        width
      } = action.value
      
      return {
        ...state,
        Chat: {
          width
        }
      }
    }
    case 'userData':{
      return {
        ...state,
        UserData: action.value
      }
    }
    case 'roomData':{
      const { 
        logout
      } = action.value
      
      return {
        ...state,
        RoomData: {
          logout
        }
      }
    }
  }
}

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <context.Provider value={{state, dispatch}}>
      {children}
    </context.Provider>
  );
};

export default context