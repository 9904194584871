import React from 'react';
import ReactDOM from 'react-dom';
import App from './ui/components/app/component';
import { Provider } from "./ui/components/layout/context";

const root = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <App />
    </Provider>
  </React.StrictMode>
, root);