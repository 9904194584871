import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .7);
  transition: width .5s;
  cursor: default;
`

export default { 
  Wrapper
};