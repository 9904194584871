import styled from 'styled-components';

const Wrapper =styled.div`
  display: flex;
  flex-flow: row;
  flex: 1;
  position: relative;
  padding: 0 0 15px;
  box-sizing: border-box;
  pointer-events: auto;
`

const Content =styled.div`
  flex: 1;
  display: flex;
  flex-flow: column;
  overflow-x: hidden;
  width: calc(100% - 1.7rem);
`

const Meta =styled.div`
  display: flex;
  flex: 1;
  flex-flow: row;
  line-height: 1.35;
  align-items: baseline;
  padding: 0 0 10px;
  box-sizing: border-box;
`

const Name =styled.div`
  display: flex;
  min-width: 0;
  font-weight: 600;
  position: relative;
  color: #cfcfcf;
`

const Time =styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 3.5rem;
  text-transform: uppercase;
  font-size: 75%;
  margin: 0 0 0 .5rem;
`

const Message =styled.p`
  font-weight: bold;
  white-space: pre-wrap;
  margin: 0;
  line-height: 1.5rem;
  word-wrap: break-word;
  text-wrap: wrap;
`

export default {
  Wrapper,
  Content,
  Meta,
  Name,
  Time,
  Message
}