import styled from 'styled-components';

const ResetButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  left: auto;
  background-color:  #ff000000;
  cursor: pointer;
  border: 0;
  z-index: 2;
  margin: 132px 2px 2px;

  [dir="rtl"] & {
    right: auto;
    left: 1.75rem;
  }
`;

const ResetButton = styled.img`
  padding: 5px;
  width: 40px;
  height: 40px;

  &,
  &:active,
  &:hover,
  &:focus {
    background-color: #ff000000 !important;
    border: none !important;

    i {
      border: none !important;
      background-color: #ff000000 !important;
      font-size: 2rem;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export default {
  ResetButtonWrapper: ResetButtonWrapper,
  ResetButton,
};
