import React, { useContext, useEffect, useState } from "react";
import styled from './styles';
import context from '../layout/context';
import MessageForm from "./message-form/component.jsx";
import { chatWs } from "../../api/service";
import TimeWindowValue from "./time-window-value/conponent";
import { bulletChat } from "../../api/bulletChat";

const Chat = (props) => {
    const chat = useContext(context).state.Chat
    const userData = useContext(context).state.UserData
    const dispatch = useContext(context).dispatch
    const [userChatText, setUserChatText] = useState('')
    const{
        roomData,
        changeNotification
    } = props

    useEffect(()=>{
        chatWs.emit('join', userData.username,(resp)=>{
            console.log(`[Chat] Received join room callback: ${JSON.stringify(resp)}`)
        })
    },[])

    const hide = () => {
        dispatch({
            type: "chat",
            value: {
                width: 0
            }
        })
    }

    const submit = (message) => {
        let msg = {
            action: 'user-publish',
            text:  message
        };

        chatWs.emit("chat", msg, (resp) => {
            console.log(`[Chat] Received send callback: ${resp}`);
        });
        setUserChatText(message)
        roomData?.chatDanmakuEnabled == 'true' && bulletChat(message, '#bulletBox')
    }

    return (
        <styled.ChatContainer 
            data-test='chat'
            style={{
                width: chat.width,
            }}
        >
            <styled.Chat>
                <styled.Header>
                    <styled.CloseBottom onClick={() => {hide()}} >
                        <img style={{width: '1rem', height: '1rem', marginRight: '3px'}} src="icons/icon_left_arrow.svg" />
                         關閉 聊天室
                    </styled.CloseBottom>
                </styled.Header>
                <TimeWindowValue chat={chat} userChatText={userChatText} setUserChatText={(data)=>{setUserChatText(data)}} roomData={roomData} changeNotification={changeNotification} />
                <MessageForm submit={submit} />
            </styled.Chat>
        </styled.ChatContainer>
    )
}

export default Chat;