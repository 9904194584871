import styled, {keyframes} from 'styled-components';

const VideoJSContainer = styled.div`
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(calc(-50% - 70px));
  background: black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & video{
    pointer-events: none;
    cursor: grab;
  }

  & div.vjs-control-bar{
    opacity: 0;
  }

  &:hover{
    & div.vjs-control-bar{
      opacity: 1;
    }
  }

  & div.vjs-live-display {
    display: none;
  }
`

const ChatContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50%;
  pointer-events: none;
`

const BulletBox = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 33%;
  pointer-events: none;
`

const Option = styled.div`
  color: white;
  cursor: pointer;

  &:hoveer{
    background: rgba(50, 50, 50, .5);
  }
`

const hide = keyframes`
  0% { opacity: 1 }
  100% { opacity: 0 }
`

const VideoRatio = styled.div`
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  background: rgba(200, 200, 200, 0.5);
  font-size: 1.5rem;
  padding: 5px 20px;
  border-radius: 16px;
  z-index: 2;
  opacity: 0;
  transition: opacity 2s;
  &.animation{
    animation: 2s ${hide}
  }
`

export default { 
  VideoJSContainer,
  ChatContainer,
  BulletBox,
  Option,
  VideoRatio
};