import React, { useEffect, useState, useContext } from "react";
import styled from './styles';
import { AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import ChatTextComponent from "../chat-text-component/component.jsx";
import { chatWs } from "../../../api/service";
import { bulletChat } from "../../../api/bulletChat";
import context from "../../layout/context";

const cache = new CellMeasurerCache({
  fixedWidth: true,
  minHeight: 18
})

const TimeWindowValue = (props) => {
  const [timeWindowsValues, setTimeWindowsValues] = useState([{ name: '', message: '', time: '' }])
  const [scrollPosition, setScrollPosition] = useState(0)
  const [userScrollBack, setUserScrollBack] = useState(false)
  const userData = useContext(context).state.UserData
  const listRef = React.createRef()
  const urlParams = new URLSearchParams(window.location.search);
  const history = urlParams.get('history')
  const{
    userChatText,
    chat,
    setUserChatText,
    roomData,
    changeNotification
  } = props

  useEffect(()=>{
    if(roomData !== undefined){
      chatWs.off('chat')
      history !== '1' && chatWs.on('chat', function(_msg) {
        const msg = JSON.parse(_msg);
        roomData?.chatDanmakuEnabled == 'true'  && bulletChat(msg.payload.text, '#bulletBox')
        saveChatData({
          name: msg.payload.sender.name,
          message: msg.payload.text
        })
      });
    }
  },[roomData, timeWindowsValues])

  useEffect(()=>{
    changeNotification && changeNotification(timeWindowsValues, scrollPosition)
  },[timeWindowsValues, scrollPosition])

  useEffect(()=>{
    !userScrollBack && chat.width !== 0 && setScrollPosition(timeWindowsValues.length - 1)
  },[chat.width])

  useEffect(()=>{
    if(userChatText !== ''){
      saveChatData({name: userData.username, message: userChatText})
      setUserChatText && setUserChatText('')
    }
  },[userChatText])

  useEffect(()=>{
    cache.clear(timeWindowsValues.length - 1);
    listRef.current.recomputeRowHeights(timeWindowsValues.length - 1)
  },[timeWindowsValues])

  const saveChatData = (data) => {
    const nowtime = new Date
    const showtime = nowtime.getHours() + ':' + (nowtime.getMinutes() < 10 ? "0" + nowtime.getMinutes() : nowtime.getMinutes())
    const newTimeWindowsValues = [...timeWindowsValues, {name: data.name, message: data.message, time: showtime }]
    newTimeWindowsValues.length > roomData?.historyChat && newTimeWindowsValues.splice(1, 1)

    setTimeWindowsValues(newTimeWindowsValues)
    !userScrollBack && chat.width !== 0 && setScrollPosition(newTimeWindowsValues.length-1)
  }

  const rowRender =({
    index,
    parent,
    style,
    key,
  }) => {
    const data = timeWindowsValues[index];
    if(!data.message || data.message == '' || chat.width == 0){
      return (<></>)
    }
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        columnIndex={0}
        parent={parent}
        rowIndex={index}
      >
        <span
          style={style}
          key={`span-${key}-${index}`}
        >
          <ChatTextComponent 
            data={data} 
            height={style.height}
          />
        </span>
      </CellMeasurer>
    );
  }

  return(
    <styled.ChatRoom
      onWheel={(e)=>{
        e.deltaY < 0 && setUserScrollBack(true)
      }}
      style={{
        scrollBehavior: "smooth",
      }}>
      <AutoSizer disableWidth>
        {({ height }) => {
        return (
          <styled.MessageList 
            isScrolling
            ref={listRef}
            height={height}
            rowRenderer={rowRender}
            rowHeight={cache.rowHeight}
            rowCount={timeWindowsValues.length}
            width={300 - 20}
            overscanRowCount={5}
            deferredMeasurementCache={cache}
            scrollToIndex={!userScrollBack ? scrollPosition : undefined}
            onScroll={({ clientHeight, scrollTop, scrollHeight })=>{
              clientHeight + scrollTop >= scrollHeight && setUserScrollBack(false)
            }}
            onRowsRendered={({ stopIndex }) => {
              timeWindowsValues.length - 1 == stopIndex && chat.width !== 0 && setScrollPosition(stopIndex);
              timeWindowsValues.length - 1 == scrollPosition && userScrollBack && chat.width !== 0 && setUserScrollBack(true)
            }}
          />
        )}}
      </AutoSizer>
  </styled.ChatRoom>
  )
}

export default TimeWindowValue