import { isMobile } from "react-device-detect";

let emojiCount = {}
let emojiQueue = {}
let setChatWidth = 0
let unfocus = false
const emojiLimit = isMobile ? 10 : 10

const getRandom = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getSize = (num) => {
  return Math.min(1 + parseInt((num/10).toFixed()) * 0.1, 2)
}

let emojiMap = {};
emojiMap.astonished = 'astonished-face_1f632.png';
emojiMap.heart = 'red-heart_2764-fe0f.png';
emojiMap.happy = 'grinning-squinting-face_1f606.png';
emojiMap.applause = 'clapping-hands_1f44f.png';
emojiMap.thumbsUp = 'thumbs-up_1f44d.png';

export default emojiMap

const MutiemojiShowHide = (emoji, chatWidth = setChatWidth) => {
  if(unfocus){
    return
  }
  let emojiName = emoji.name
  let emojiNumber = emoji.number
  let emojiRadius = 50

  let xShift = chatWidth + emojiRadius * getSize(emojiNumber);
  if (isMobile) {
    xShift = emojiRadius * getSize(emojiNumber);
  }
  
  let snowId = getRandom(1, 10000);
  let viewport_width = document.documentElement.clientWidth;
  let offsetX = getRandom(20, viewport_width - xShift);
  let offsetY = getRandom(0, 5) + 30;
  let duration = getRandom(1, 4);
  let timeOutDuration = (+duration) * 1000;
  let shiftNum = getRandom(100, 200);

  let emojiWrapper = document.createElement("div");

  emojiWrapper.id = snowId;
  emojiWrapper.style.display = 'flex'
  emojiWrapper.style.flexDirection = 'column'
  emojiWrapper.style.alignItems = 'center'
  emojiWrapper.style.justifyContent = 'center'
  emojiWrapper.style.left = offsetX + "px";
  emojiWrapper.style.bottom = offsetY + "px";
  emojiWrapper.style.position = "absolute";
  emojiWrapper.style.zIndex = "10000";
  emojiWrapper.style.pointerEvents = "none";
  emojiWrapper.animate(
    [
      { transform: 'translateY(0)', opacity: '0' },
      { transform: 'translateY(-' + shiftNum + 'px)', opacity: '1' },
      { transform: 'translateY(-' + shiftNum + 'px)', opacity: '0', display: 'none' }
    ], {
    duration: timeOutDuration,
    iterations: 1
  }
  ).onfinish = () => {
    emojiWrapper.style.display = "none";
    emojiWrapper.remove();
  };

  let emojiValue = document.createElement("div");
  emojiValue.innerHTML = 'x' + emojiNumber
  emojiValue.style.color = '#FF5050'
  emojiValue.style.fontSize = getSize(emojiNumber) + 'rem'
  // emojiValue.style.textShadow = '-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000'
  emojiWrapper.append(emojiValue)

  let snowflake = document.createElement("div");
  snowflake.style.backgroundImage = "url('emojis/" + emojiMap[emojiName] + "')";
  snowflake.style.width = emojiRadius * getSize(emojiNumber) + "px";
  snowflake.style.height = emojiRadius * getSize(emojiNumber) + "px";
  snowflake.style.backgroundSize = "cover";
  emojiWrapper.append(snowflake)

  document.fullscreenElement ? document.fullscreenElement.appendChild(emojiWrapper) : document.body.appendChild(emojiWrapper);
}

export const singleEmojiShowHide = (emojiName, chatWidth = setChatWidth) => {
  setChatWidth = chatWidth
  if(!emojiCountCheck(emojiName) && unfocus){
    return
  }

  let emojiRadius = 50

  let xShift = setChatWidth + emojiRadius
  if (isMobile) {
    xShift = emojiRadius
  }
  
  let snowId = getRandom(1, 10000);
  let viewport_width = document.documentElement.clientWidth;
  let offsetX = getRandom(20, viewport_width - xShift);
  let offsetY = getRandom(0, 5) + 30;
  let duration = getRandom(1, 4);
  let timeOutDuration = (+duration) * 1000;
  let shiftNum = getRandom(100, 200);

  let emojiWrapper = document.createElement("div");

  emojiWrapper.id = snowId;
  emojiWrapper.style.display = 'flex'
  emojiWrapper.style.flexDirection = 'row'
  emojiWrapper.style.alignItems = 'center'
  emojiWrapper.style.left = offsetX + "px";
  emojiWrapper.style.bottom = offsetY + "px";
  emojiWrapper.style.position = "absolute";
  emojiWrapper.style.zIndex = "10000";
  emojiWrapper.style.pointerEvents = "none";
  emojiWrapper.animate(
    [
      { transform: 'translateY(0)', opacity: '0' },
      { transform: 'translateY(-' + shiftNum + 'px)', opacity: '1' },
      { transform: 'translateY(-' + shiftNum + 'px)', opacity: '0', display: 'none' }
    ], {
    duration: timeOutDuration,
    iterations: 1
  }
  ).onfinish = () => {
    emojiWrapper.style.display = "none";
    emojiWrapper.remove();
  };

  let snowflake = document.createElement("div");

  snowflake.style.backgroundImage = "url('emojis/" + emojiMap[emojiName] + "')";
  snowflake.style.width = emojiRadius + "px";
  snowflake.style.height = emojiRadius + "px";
  snowflake.style.backgroundSize = "cover";
  emojiWrapper.append(snowflake)

  document.fullscreenElement ? document.fullscreenElement.appendChild(emojiWrapper) : document.body.appendChild(emojiWrapper);
}

export const saveMutiEmoji = (emojiList) => {
  Object.keys(emojiList).forEach((emoji)=>{
    emojiQueue[emoji] = emojiQueue[emoji] ? emojiList[emoji] + emojiQueue[emoji] : emojiList[emoji]
  })
}

const emojiCountCheck = (emojiName) => {
  let total = 0
  Object.keys(emojiCount).forEach((emoji)=>{
    total += emojiCount[emoji] ? emojiCount[emoji] : 0
  })
  
  emojiCount[emojiName] = emojiCount[emojiName] ? emojiCount[emojiName] + 1 : 1
  total > emojiLimit && (emojiQueue[emojiName] = emojiQueue[emojiName] ? emojiQueue[emojiName] + 1 : 1)
  
  return total > emojiLimit ? false : true
}

setInterval(() => {
  Object.keys(emojiQueue).forEach((emoji)=>{
    if(emojiQueue[emoji] == 0)
      return
      
    emojiQueue[emoji] == 1 ? 
    singleEmojiShowHide(emoji) : 
    MutiemojiShowHide({
      name: emoji,
      number: emojiQueue[emoji]
    })
  })
  emojiQueue={}
  emojiCount={}
}, 1000);

window.addEventListener('blue',()=>{
  unfocus = true
})

window.addEventListener('focus',()=>{
  unfocus = false
})