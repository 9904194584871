import styled, { keyframes } from 'styled-components';

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1;
  box-sizing: border-box;
  background-color: #737373;
  text-align: center;
`

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  line-height: 18px;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  vertical-align: middle;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 10px #000;
`

const Header = styled.h3`
  margin: 0;
  color: #333;
  font-size: 1.8rem;
  padding: 30px 0;
  border-bottom: 1px solid #cccccf;
  background-image: linear-gradient(to top, #e4e4e4, #fff);
`

const RoomName = styled.h1`
  padding: 0 30px;
  font-size: 1.3rem;
  line-height: 1.5em;
  color: #495057;
  font-family: "Noto Sans TC", "Heiti TC", 微軟正黑體, "Microsoft JhengHei"
`

const InsertName = styled.input`
  font-size: 20px !important;
  width: 80%;
  text-align: center;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ddd;
  margin: 3px 0;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font: normal 12px/18px "Lucida Grande", Verdana;
`

const JoinAsVisitors = styled.button`
  width: 86%;
  height: 3.5rem;
  min-width: 1.625rem;
  font-size: 1rem !important;
  font: normal 12px/18px "Lucida Grande", Verdana;
  letter-spacing: .03em;
  margin: 5px 0;
  background-color: #607DEF;
  color: white;
  background-clip: padding-box;
  border-radius: 0.6rem;
  border-color: transparent;
  cursor: pointer;

  &:hover{
    background-color: #0085b7;
  }
`

const Error = styled.div`
  font-size: 20px !important;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  width: 80%;
  background: #fff;
  padding: 15px 30px;
  border-radius: 6px;
  box-shadow: 0 0 10px #000;
  text-align: left;
  max-width: 450px;
`

const CloseBotton = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 50% 50%;
  color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover{
    background-color: #078bbd;
  }
`

const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0px);
  }
`

const TextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  -webkit-text-stroke: 1px #000000;
  color: white;
  font-size: 5rem;
  
  & > .animation{
    animation: 3s ${float} ease-in-out infinite;
  }

  & > .animation1{
    animation: 3s ${float} 0.5s ease-in-out infinite;
  }

  & > .animation2{
    animation: 3s ${float} 1s ease-in-out infinite;
  }
`

export default {
  Background,
  Wrapper,
  Header,
  RoomName,
  InsertName,
  JoinAsVisitors,
  Error,
  CloseBotton,
  TextWrapper
}