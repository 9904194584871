import React, { useContext, useEffect, useState } from 'react';
import context from '../layout/context';
import styled from './styles';
import { streamWs, withTimeout } from "../../api/service";

function Login(props){
  const dispatch = useContext(context).dispatch
  const refInput = React.createRef()
  const [error, setError] = useState(false)
  const [userData, setUsertData] = useState()
  let loading = null, dot = 0
  const {
    roomData
  } = props

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const liveSessionToken = urlParams.get('liveSessionToken')
    if(!liveSessionToken){
      window.location.href = 'https://auth.mplusmeeting.com/m/joinMeeting'
    }

    loading = setInterval(()=>{
      let text = '正在接收系統回應'
      for (let i = 0; i <= (dot % 2); i++){
        text += '.'
      }
      refInput.current.value = text
      dot++
    },1000)

    // localStorage.getItem('userName') && (refInput.current.value = localStorage.getItem('userName'))
    streamWs.on("connect", () => {
      joinSocket(1)
      clearInterval(loading)
    });
  },[])

  const joinSocket = (times) => {
    streamWs.emit('join', {}, withTimeout((resp) => {
      console.info("[live-stream] Join room successfully.");
      console.info(`[live-stream] Received join room callback: ${JSON.stringify(resp)}`);
      if(resp.username){
        document.querySelector('input').value = resp.username
        setUsertData(resp)
      }else{
        times-- && joinSocket()
      }
    }, () => {
        console.error("[live-stream] Timeout");
    }, 5000));
  }

  const check = () => {
    if(refInput.current.value !== ""){
      dispatch({
        type: "userData",
        value: userData
      })
      props.setHideLoading(true)
      localStorage.setItem('userName', refInput.current.value)
    }
  }

  return (
    <styled.Background>
      {error ? 
      <styled.Error>
        <p>
          請輸入暱稱
        </p>
        <styled.CloseBotton onClick={()=>{setError(false)}}>
          x
        </styled.CloseBotton>
      </styled.Error>:
      <styled.Wrapper className='modal'>
        {/* <styled.Header>
          您已被邀請加入
        </styled.Header> */}
        <img src="icons/kv_mplusmeet.svg" width="300px"></img>
        <styled.RoomName>
          {/* {roomData.livestreamTitle} */}
          使用M+身分登入
        </styled.RoomName>
        <p style={{
          margin: '10px 0'
        }}>
          <styled.InsertName ref={refInput} type='text' disabled maxLength={20} placeholder="請輸入您的暱稱或姓名" />
        </p>
        <styled.JoinAsVisitors onClick={()=>{check()}} >
          進入會議
        </styled.JoinAsVisitors>

        {/* <p>
          <styled.JoinAsVisitors onClick={check} >
            用訪客身分進入會議
          </styled.JoinAsVisitors>
        </p>
        <p>
          <styled.JoinAsVisitors style={{
            backgroundColor: '#aaaaaa',
            cursor: 'initial'
          }} disabled>
            用M+帳號進入會議
          </styled.JoinAsVisitors>
        </p> */}
      </styled.Wrapper>}
    </styled.Background>
    // <styled.Background>
    //   <styled.TextWrapper>
    //     <span>
    //       Loadinng
    //     </span>
    //     <span className='animation'>
    //       .
    //     </span>
    //     <span className='animation1'>
    //       .
    //     </span>
    //     <span className='animation2'>
    //       .
    //     </span>
    //   </styled.TextWrapper>
    // </styled.Background>
  )
}

export default Login