import React, { useEffect, useMemo, useState } from "react";
import styled from './styles';
import ActionBar from "../../action-bar/component";

const GameFrame = (props) => {
  const refGameFrameContainer = React.createRef();
  const [showActionBar, setShowActionBar] = useState(false);
  const gameUrl = props.gameUrl;
  //const gameUrl = (game) ? `${game.gameUrl}?roomId=20220812008265521660273592846777&msgId=${game.roomId}&manualNickName=false&t=${new Date().getTime()}`: null;
  console.log("gameUrl", gameUrl);
  document.addEventListener("fullscreenchange", () => {
    document.querySelector("[data-test='fullscreen'] img").src = document.fullscreenElement ? "icons/icon_exit_fullscreen.svg" : "icons/icon_fullscreen.svg"
    setShowActionBar(true && document.fullscreenElement)
  });

  const toggleFullscreen = () => {
    document.fullscreenElement ? document.exitFullscreen() : refGameFrameContainer.current.requestFullscreen().then(()=>{
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        screen.orientation.lock('landscape')
      }
    })
  }

  return (
    <styled.GameFrameContainer
      ref={refGameFrameContainer}
      data-test="gameFrame"
    >
      <styled.GameFrame src={gameUrl}></styled.GameFrame>
      {showActionBar && <ActionBar />}
      <styled.ToolBar>
        <styled.ToolBarButton data-test='fullscreen' onClick={(e)=>toggleFullscreen(e)} >
          <img src="icons/icon_fullscreen.svg" />
        </styled.ToolBarButton>
      </styled.ToolBar>
    </styled.GameFrameContainer>
  )
}

export default GameFrame;
