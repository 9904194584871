import React, { useContext, useEffect, useState } from "react";
import ActionBar from "../action-bar/component";
import Chat from "../chat/component.jsx";
import LiveStream from "../live-stream/component";
import GameFrame from "../game/game-frame/component";
import Navbar from "../nav-bar/component";
import styled from './styles';
import context from "../layout/context";
import { singleEmojiShowHide, saveMutiEmoji } from "../../api/emojis";
import VideoJS from '../video-js/component'
import { streamWs, emojiWs } from "../../api/service";
import Login from "../login/component.jsx";
import LoadingPage from "../video-js/loding-page/component";
import MeetingEnded from "../meeting-ended/component";
import { isMobile } from "react-device-detect";
import watermark from '../../api/watermark';

const App = () => {
    const [roomData, setRoomData] = useState({})
    const [gameUrl,setGameUrl] = useState(null)
    const [showVideo, setShowVideo] = useState(true)
    const [LoadData, setLoadData] = useState({showPage: true, text: '直播加載中'})
    const [showNotification, setShowNotification] = useState(false)
    const [hideLoading, setHideLoading] = useState(false)
    const userData = useContext(context).state.UserData
    const logout = useContext(context).state.RoomData.logout
    const dispatch = useContext(context).dispatch
    const chat = useContext(context).state.Chat
    const haslogin = userData.username && userData.username !== ''
    const urlParams = new URLSearchParams(window.location.search);
    const history = urlParams.get('history')
    const getmeetdata = (times) =>{
        fetch(process.env.REACT_APP_MEETING_API_ENDPOINT ,{
            method: 'GET'
        })
        .then(response => {
            return response.json();
        }).then((data) => {
            setRoomData(data);
        }).catch(()=>{
            setTimeout(() => {
                times && getmeetdata(--times)
            }, 5000);
        })
    }

    useEffect(()=>{
        window.addEventListener('keydown', (e)=>{
            if (e.key == 'Tab'){
                e.preventDefault();
            }
        })
    },[])

    useEffect(()=>{
        console.log(userData, haslogin)
        if(haslogin){
            watermark.init({
                text: userData.user_id + userData.username
            })
        }
    },[haslogin])
    
    useEffect(()=>{
        if(!showVideo){
            setShowVideo(true)
        }
    },[showVideo])


    useEffect(()=>{
        getmeetdata(3)

        emojiWs.off('gameStart')
        emojiWs.on('gameStart', function(response) {
            const data = JSON.parse(response);
            if (data && data.game) {
                const game = data.game;
                setGameUrl(`${game.gameUrl}?roomId=20220812008265521660273592846777&msgId=${game.roomId}&manualNickName=false&t=${new Date().getTime()}`);
            }
        });

        emojiWs.off('gameEnd')
        emojiWs.on('gameEnd', function(response) {
            const data = JSON.parse(response);
            if (data && data.game) {
                const game = data.game;
                setGameUrl(null);
            }
        });
        
        // streamWs.emit('join', userData.username)
        // streamWs.on('live-stream', (_msg)=>{
        //     const msg = JSON.parse(_msg);
        //     switch(msg.payload.stream_status){
        //         case 'ChannelNotBroadcasting':
        //         case 'Session Ended':
        //             setShowVideo(false)
        //             setStreamState({
        //                 state: true, 
        //                 text: '直播暫停中'
        //             })
        //             break
        //         default :
        //             setShowVideo(true)
        //             setStreamState({
        //                 state: false, 
        //                 text: ''
        //             })
        //     }
        // })

        setTimeout(() => {
            setHideLoading(true)
        }, 10000);
    },[])

    const setStreamState = ({state, text}) => {
        setLoadData({showPage: state, text: text})
    }

    useEffect(()=>{
        if(haslogin){
            emojiWs.off('connect_error')
            emojiWs.on("connect_error", () => {
                setTimeout(() => {
                    emojiWs.connect();
                }, 5000);
            });
    
            emojiWs.off('emojis')
            history !== '1' && emojiWs.on('emojis', (response) => {
                const data = JSON.parse(response);
                data.topic == 'single' && singleEmojiShowHide(data.payload, chat.width)
                data.topic == 'aggregated' && saveMutiEmoji(data.payload)
            });
        }
    },[chat, haslogin])

//     window.oncontextmenu = function(event) {
//         event.preventDefault();
//         event.stopPropagation();
//         return false;
//    };

    const playerRef = React.useRef(null);
    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
          videojs.log('player is waiting');
        });

        player.on('dispose', () => {
          videojs.log('player will dispose');
        });
    };

    const changeNotification = (timeWindowsValues, scrollPosition) =>{
        if(timeWindowsValues?.length - 1 !== scrollPosition){
            setShowNotification(true)
        }else{
            setShowNotification(false)
        }
    }
    return haslogin && hideLoading ? (
        logout ? (
            <MeetingEnded />
        ) : (
        <styled.MainContainer>
            <styled.MediaWapper
                style={{
                    width: isMobile ? "100%" : `calc(100% - ${chat.width}px)`,
                    minWidth: !isMobile ? '550px' : ''
                }}>
                <Navbar 
                livestreamTitle={roomData.livestreamTitle} 
                livestreamMediaFile={roomData.livestreamMediaFile}
                showNotification={showNotification} 
                chatEnabled={roomData.chatEnabled}/>
                { gameUrl ? <GameFrame gameUrl={gameUrl} /> : null}
                { showVideo && roomData.livestreamUrl ? <VideoJS 
                livestreamUrl={history == '1' ? roomData.historystreamUrl : roomData.livestreamUrl} 
                mediaSource={roomData.mode}
                setLoadData={setLoadData} 
                onReady={handlePlayerReady}
                setStreamState={setStreamState}
                setShowVideo={setShowVideo}
                userData={userData}
                 /> : null }
                {(!true || LoadData.showPage) && <LoadingPage text={LoadData.text} />}
                <ActionBar />
            </styled.MediaWapper>
            <Chat roomData={roomData} changeNotification={(timeWindowsValues, scrollPosition)=>changeNotification(timeWindowsValues, scrollPosition)} />
        </styled.MainContainer>
        )
    ) : (
        <Login roomData={roomData} setHideLoading={setHideLoading} />
    )
}

export default App;
