import styled from 'styled-components';

const ActionBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 64.4px;
  `

const ActionBar =  styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: transform .5s;
  background: rgba(127,127,127,0.5);
  padding: 16px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  `

const EmojiBotton = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin: 0 5px;

  &>img{
    -webkit-user-drag: none;
  }
`

const HideBotton = styled.div`
  width: 32px;
  height: 32px;
  cursor: all;
  margin: 0 0.5rem;

  &>img{
    -webkit-user-drag: none;
  }
`

const LeaveMeetingButton = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin: 0 0.5rem;

  &::after{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 46px;
    height: 46px;
    background-color: red;
    border-radius: 50%;
    content: '';
    z-index: -1;
  }
`

export default { 
  ActionBarContainer,
  ActionBar,
  EmojiBotton,
  HideBotton,
  LeaveMeetingButton
  };