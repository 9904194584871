import styled from 'styled-components';

const LiveStreamContainer = styled.div`
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(calc(-50% - 70px));
  background: black;

  &>video{
    object-fit: fill;
  }
  &>video::-webkit-media-controls-timeline {
    display: none;
  }
  &>video::-webkit-media-controls-play-button {
    display: none;
  }
  &>video::-webkit-media-controls-current-time-display{
    display: none;
  }
  &>video::-webkit-media-controls-time-remaining-display {
    display: none;
  }
  `

const ToolBar = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
`

const ToolBarButton = styled.div`
  position: relative;
  height: 30px;
  width: 30px;
  margin: 0.5rem;
  cursor: pointer;

  &>img{
    -webkit-user-drag: none;
  }
`

export default { 
  LiveStreamContainer,
  ToolBar,
  ToolBarButton
  };